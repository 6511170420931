import { Effect, select, takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';

import { createErrorAlertAction } from '../../common/actions/creators/alert';
import { PaymentExtensionPayload, PaymentRequestPayload } from '../../common/actions/creators/payments';
import { createDraftsReceivedAction } from '../../common/actions/creators/requests';
import { PAYMENT_EXTENSION_REQUESTED, PAYMENT_REQUEST_REQUESTED, PAYMENT_REQUESTED } from '../../common/actions/types';
import { getCostCalculation, getMyRequests, initializePayment } from '../../common/api';
import { appUrls, WORKFLOW_STATE_NEW } from '../../common/constants';
import debug from '../../common/debug';
import { PaymentType } from '../../common/enums';
import { historyPush } from '../../common/utils/historyUtils';
import { IGenericAction } from '../actions/actions';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { FeatureName, ICall, IRequestPayment, ISelect } from '../../types';
import { isFeatureEnabled, selectCarFreeZones } from '../selectors';
import { SagaIterator } from 'redux-saga';

function* payRequest(action: IGenericAction<PaymentRequestPayload>): IterableIterator<Effect> {
  const process = 'payRequest';
  yield put(createProcessStartedAction(process));
  try {
    const request = action.payload.request;
    if (request.payments && request.payments.length > 0) {
      const payment = request.payments
        .filter((x) => x.amountToBePaid > 0)
        .filter((x) => x.state.state === WORKFLOW_STATE_NEW)[0];
      if (payment) {
        const integrateDigipolisSalesIntegration = yield select(
          isFeatureEnabled(FeatureName.integrateDigipolisSalesIntegration),
        );

        const response: ICall<typeof initializePayment> = yield call(
          initializePayment,
          payment,
          request,
          integrateDigipolisSalesIntegration,
        );

        // Redirect
        if (request.paymentType === PaymentType.Online) {
          window.location.href = integrateDigipolisSalesIntegration ? response!.paymentUrl : response!.ogoneUrl;
        } else {
          window.location.href = response!.successUrl;
        }
      } else {
        yield put(createErrorAlertAction('Er moet niets betaald worden'));
      }
    } else {
      const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
      const cost: ICall<typeof getCostCalculation> = yield call(getCostCalculation, request, carFreeZones!);
      if (cost!.totalCost === 0) {
        const drafts: ICall<typeof getMyRequests> = yield call(getMyRequests, 'desc', 'id');
        yield put(createDraftsReceivedAction(drafts!));
        historyPush(appUrls.request.submit.success.replace(':id', `${request.id}`));
      } else {
        yield put(createErrorAlertAction('Er zijn geen betalingsgegevens beschikbaar voor deze aanvraag'));
      }
    }
  } catch (e) {
    yield put(createErrorAlertAction('De betaling kon niet worden uitgevoerd'));
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

function* payExtension(action: IGenericAction<PaymentExtensionPayload>): IterableIterator<Effect> {
  const process = 'payExtension';
  yield put(createProcessStartedAction(process));

  try {
    const { request, extension } = action.payload;

    // Get the latest cost
    const shouldPay =
      (extension.payments || [])
        .filter((x) => x.state.state === WORKFLOW_STATE_NEW)
        .reduce((p, c) => p + c.amountToBePaid, 0) > 0;

    if (shouldPay) {
      const payment = extension.payments
        .filter((x) => x.amountToBePaid > 0)
        .filter((x) => x.state.state === WORKFLOW_STATE_NEW)[0];
      const integrateDigipolisSalesIntegration = yield select(
        isFeatureEnabled(FeatureName.integrateDigipolisSalesIntegration),
      );
      const response: ICall<typeof initializePayment> = yield call(
        initializePayment,
        payment,
        request,
        integrateDigipolisSalesIntegration,
      );

      // Redirect to ogone
      if (window) {
        window.location.href = integrateDigipolisSalesIntegration ? response!.paymentUrl : response!.ogoneUrl;
      }
    }
  } catch (e) {
    debug(e);
    yield put(createErrorAlertAction('De betaling kon niet worden uitgevoerd'));
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

function* payPayment(action: IGenericAction<IRequestPayment>): IterableIterator<Effect> {
  const process = 'payPayment';
  yield put(createProcessStartedAction(process));

  try {
    const response: ICall<typeof initializePayment> = yield call(initializePayment, action.payload);

    // Redirect to ogone
    window.location.href = response!.ogoneUrl;
  } catch (e) {
    yield put(createErrorAlertAction('De betaling kon niet worden uitgevoerd'));
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

export default function* paymentsSaga(): SagaIterator {
  yield takeLatest(PAYMENT_EXTENSION_REQUESTED, payExtension);
  yield takeLatest(PAYMENT_REQUESTED, payPayment);
  yield takeLatest(PAYMENT_REQUEST_REQUESTED, payRequest);
}
